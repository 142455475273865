<template>
  <div>
    <el-card>
      <div class="flex flex-align-center flex-wrap gap-20">
        <div class="flex flex-align-center gap-10">
          <label class="keyLabel">关键字: </label>
          <el-input v-model="searchKey" placeholder="订单编号" @keyup.enter.native="goodsFilter" style="width: 200px;" clearable></el-input>
        </div>
        <div class="flex flex-align-center gap-10">
          <label class="keyLabel">收益状态: </label>
          <el-select v-model="earnings" style="width:200px">
            <el-option v-for="(item,index) in earningsList" :key="index+'-earnings'" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="flex flex-align-center gap-10">
          <label class="keyLabel">收益获得时间: </label>
          <el-date-picker
            v-model="searchTime"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <el-button type="primary" @click="goodsFilter">查询</el-button>
        <buttonPermissions :datas="'taokeDistributionEarningsExport'">
          <el-button @click="exportFun">导出</el-button>
        </buttonPermissions>
      </div>
      <div class="margin-top-10">
        <el-table :data="tablelist" v-loading="tableloading">
          <el-table-column prop="TaoKeOrderNo" label="订单" min-width="120"></el-table-column>
          <el-table-column prop="" label="用户">
            <template slot-scope="scope">
              <div>
                <div class="ellipsis">{{scope.row.WxNickname}}</div>
                <div>{{scope.row.Phone}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="推手级别">
            <template slot-scope="scope">
              <div>
                {{scope.row.AgentRoleName||'--'}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="收益比">
            <template slot-scope="scope">
              <div>
                {{scope.row.PromotionRate*100}}%
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="预估收益（元）">
            <template slot-scope="scope">
              <div>
                ￥{{scope.row.PredictPromotionMoney}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="结算收益（元）">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.SettlePromotionMoney==0&&scope.row.State==0">--</span>
                <span v-else>￥{{scope.row.SettlePromotionMoney}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="收益状态">
            <template slot-scope="scope">
              <div class="flex">
                <div :style="{'color':scope.row.State==2?'#F56C6C':''}">{{scope.row.StateValue}}</div>
                <el-popover placement="top-start" trigger="hover" v-if="scope.row.State==2">
                  <div>
                    {{scope.row.PromotionCheckFailReason}}
                  </div>
                  <span style="color: #F56C6C" slot="reference">
                    <i class="el-icon-warning-outline margin-left-5"></i>
                  </span>
                </el-popover>
              </div>
              
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="获得时间" width="170"></el-table-column>
          <el-table-column prop="" label="结算时间" width="170">
            <template slot-scope="scope">
              <div>
                {{scope.row.SettlementTime||'--'}}
              </div>
            </template>
          </el-table-column>
        </el-table>
        
        <div style="text-align: right;">
          <el-pagination v-if="Total" style="margin-top:10px" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="Total">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import {
  taoKeCommissionRecordlist
} from "@/api/sv3.0.0"
import {
  nextDay
} from "@/utils"
export default {
  components:{
    buttonPermissions
  },
  data () {
    return {
      searchKey: '',
      searchTime:[],
      earnings: null,
      earningsList: [
        {value:null,label:'全部'},
        {value:0,label:'待结算'},
        // {value:2,label:'审核中'},
        // {value:3,label:'结算中'},
        {value:1,label:'已结算'},
        {value:2,label:'已失效'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pageSize:10,
      Total:0,
    }
  },
  mounted () {
    let params = this.$route.params
    if(params&&params.Id){
      this.searchKey = params.Id
    }
    this.gettablelist()
  },
  methods: {
    exportFun(){
      let url = location.origin + '/pc/taoKeCommissionRecord/export?'
      url += 'Keywords=' + encodeURI(this.searchKey)
      url += '&State=' + (this.earnings==null?'':this.earnings)
      url += '&IsExport=' + true
      if(this.searchTime&&this.searchTime.length){
        url += '&StartTime=' + this.searchTime[0]
        url += '&EndTime=' + nextDay(this.searchTime[1],1)
      }
      window.open(url)
    },
    goodsFilter(){
      this.currentPage = 1
      this.gettablelist()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pageSize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Keywords:this.searchKey,
          State:this.earnings,
          Skip:(this.currentPage-1)*this.pageSize,
          Take:this.pageSize
        }
        if(this.searchTime&&this.searchTime.length){
          data.StartTime = this.searchTime[0]
          data.EndTime = nextDay(this.searchTime[1],1)
        }
        let res = await taoKeCommissionRecordlist(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.Total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }
  }
}
</script>

<style lang='less' scoped>

</style>